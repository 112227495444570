<template>
  <Dialog v-if="visible" @close="handleClose" :width="'70%'" :height="'70%'">
    <div class="dialog-header">
      <h2 class="dialog-title">
        {{ title || 'Select Your Details' }}
      </h2>
    </div>
    <div v-if="loading" class="local-loader-container">
      <div class="inava-loader"></div>
    </div>

    <div>
      <p v-if="description" v-html="description"></p>
      <br />
      Current Hierarchy:
      <Grid
        ref="grid-table"
        :style="{ height: '30%', minHeight: '110px', width: '100%' }"
        :data-items="singleRecordGridData"
        :resizable="true"
        :reorderable="true"
        :columns="singleRecordColumns"
        :expand-field="'expanded'"
      >
      </Grid>
    </div>

    <div>
      <p :style="{ paddingTop: '12px' }">Hierarchy for Reference:</p>
      <Grid
        ref="grid-table"
        :style="{ height: '100%', minHeight: '150px', width: '100%' }"
        :data-items="multiRecordGridData"
        :resizable="true"
        :reorderable="true"
        :columns="multiRecordColumns"
        :expand-field="'expanded'"
      >
      </Grid>
    </div>
    <!-- Dialog Action Buttons -->
    <DialogActionsBar>
      <div class="action-buttons">
        <b-button class="action-button cancel-button" @click="handleClose">
          Close
        </b-button>
      </div>
    </DialogActionsBar>
  </Dialog>
</template>

<script>
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs'
import { Grid, GridColumn } from '@progress/kendo-vue-grid'
import { Input } from '@progress/kendo-vue-inputs'

export default {
  name: 'DMPSelectableMultipleGrid',
  components: {
    Dialog,
    DialogActionsBar,
    Grid,
    GridColumn,
    'k-input': Input
  },
  props: {
    description: {
      type: String
    },
    visible: {
      type: Boolean,
      default: false
    },
    multiRecordDataSource: Array,
    singleRecordDataSource: Array,
    title: String,
    closeDialog: Function,
    confirm: Function
  },
  data() {
    return {
      loading: false,
      singleRecordGridData: this.singleRecordDataSource,
      multiRecordGridData: this.multiRecordDataSource
    }
  },
  computed: {
    multiRecordColumns() {
      let columns = []
      if (this.multiRecordDataSource?.length > 0) {
        columns.push(
          ...Object.keys(this.multiRecordDataSource[0]).map((key) => {
            const colData = {
              field: key,
              title: key
            }
            return colData
          })
        )
      }
      return columns
    },
    singleRecordColumns() {
      let columns = []
      if (this.singleRecordDataSource?.length > 0) {
        columns.push(
          ...Object.keys(this.singleRecordDataSource[0]).map((key) => {
            const colData = {
              field: key,
              title: key
            }
            return colData
          })
        )
      }
      return columns
    }
  },
  watch: {
    singleRecordDataSource(newDataItems) {
      this.singleRecordGridData = newDataItems
    },
    multiRecordDataSource(newDataItems) {
      this.multiRecordGridData = newDataItems
    }
  },
  methods: {
    handleClose() {
      this.closeDialog()
    },
    handleConfirm() {
      this.handleClose()
    }
  }
}
</script>

<style scoped lang="scss">
$primary-color: #3f6ad8;

.local-loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  .inava-loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
}

.dialog-header {
  padding: 10px;
  border-radius: 5px 5px 0 0;
  text-align: left;
  .dialog-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    color: $primary-color;
  }
}

.k-input {
  border: 1px solid #cccccc;

  input {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    font-family: Quicksand;
    font-style: normal;
  }

  .k-input-inner {
    margin-left: 0.1rem;
  }

  .k-input-prefix {
    margin-left: 0.5rem;
    color: #cccccc;
  }
}
.k-textbox {
  width: 100%;
  padding: 10px;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 0.5rem;

  &:focus {
    border-color: $primary-color;
    outline: none;
  }
}

::v-deep .k-table-td {
  // Removed filter icon on grid
  .k-filtercell {
    .k-filtercell-wrapper {
      .k-filtercell-operator {
        display: none !important;
      }
    }
  }
}

.radio-column {
  text-align: center;
  .radio-button {
    height: 17px;
    width: 17px;
  }
}
.k-dialog-wrapper {
  z-index: 999;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  .action-button {
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    text-align: center;
    font-size: 16px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.18px;
    border-radius: 8px;
    padding: 8px;
  }
  .cancel-button {
    background-color: #cccccc;
    opacity: 1;
    color: #444444;
    &:hover {
      background-color: #cccccc;
      opacity: 0.9;
    }
  }
  .confirm-button {
    background-color: #535eeb;
    color: #ffffff;
    opacity: 1;
    margin-left: 1rem;
    &:hover {
      background-color: #00218a;
      opacity: 0.9;
    }
  }
}
.grid-link {
  color: #007bff; /* Custom hyperlink color */
  text-decoration: none; /* Removes underline */
}

.grid-link:hover {
  color: #0056b3; /* Darker color on hover */
  text-decoration: underline; /* Adds underline on hover */
}

.view-icon {
  cursor: pointer;
  color: #535eeb;
}
</style>
