import { render, staticRenderFns } from "./DMPSelectableMultipleGrid.vue?vue&type=template&id=367b2c47&scoped=true&"
import script from "./DMPSelectableMultipleGrid.vue?vue&type=script&lang=js&"
export * from "./DMPSelectableMultipleGrid.vue?vue&type=script&lang=js&"
import style0 from "./DMPSelectableMultipleGrid.vue?vue&type=style&index=0&id=367b2c47&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "367b2c47",
  null
  
)

export default component.exports